type stringOrUndefined = string | undefined

/**
 * mergeClasses is designed to be used when combining a list of default classes with user-provided classes
 * provided by react's className prop. It can take any number of args, either as a list of strings or raw strings,
 * and prioritizes later classes when merging.
 */
export function mergeClasses(
    ...args: (stringOrUndefined | stringOrUndefined[])[]
): string {
    return Array.from(
        new Set<string>(
            args.reduce<string[]>((classNames, arg) => {
                if (arg === undefined) {
                    return classNames
                }

                // Either we're a string
                if (typeof arg === "string") {
                    return classNames.concat(...arg.split(" "))
                }

                // Or an array
                return classNames.concat(
                    ...arg.reduce<string[]>(
                        (items, item) =>
                            item ? items.concat(...item.split(" ")) : items,
                        [],
                    ),
                )
            }, []),
        ),
    )
        .filter((item) => Boolean(item))
        .join(" ")
        .trim()
}
